import * as React from 'react';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import { ReactComponent as Chevron } from '~/assets/chevron.svg';
import { ReactComponent as Check } from '~/assets/icon/check.svg';
import { customStyles } from '~/components/form/select/style';
import { ICON_TYPE } from '~/helpers/constants';
import WorkingShiftIcon from '~/components/icon/workingShiftIcon';
import PriorityIcon from '~/components/icon/PriorityIcon';

interface ISelect {
  name?: string;
  options: ISelectOption;
  preparedOptions?: ISelectPreparedOptions;
  defaultValue?: string | number | null | any;
  placeholder?: string;
  isSearchable?: boolean;
  onChange: (value: ISelectValue, name: string) => void;
  className?: string;
  classNameWrapper?: string;
  label?: string;
  iconName?: string;
  typeColor?: string;
  loading?: boolean;
  multi?: boolean;
  multiColor?: boolean;
  disabled?: boolean;
  openMenuOnClick?: boolean;
  hiddenValue?: boolean;
  hideIndicator?: boolean;
  error?: string;
  errorBorder?: boolean;
  useClearable?: boolean;
}

const SelectsField = ({
  options,
  preparedOptions,
  defaultValue,
  placeholder = '',
  isSearchable = false,
  onChange,
  className,
  classNameWrapper,
  label,
  iconName,
  typeColor,
  loading,
  multi,
  disabled,
  openMenuOnClick,
  hiddenValue,
  hideIndicator,
  name,
  error,
  errorBorder,
  useClearable,
}: ISelect) => {
  if (!options) {
    return null;
  }

  const prepareOptions = (options: ISelectOption) => {
    const array = Object.keys(options).map(key => {
      return { value: key, label: options[key] };
    });

    const empty = array.findIndex(elem => elem.value === '');

    if (empty !== -1) {
      array.splice(0, 0, array.splice(empty, 1)[0]);
    }

    return array;
  };

  const innerPreparedOptions = preparedOptions ?? prepareOptions(options);

  const _defaultValue = multi
    ? defaultValue
    : innerPreparedOptions.find((option: any) => option.value == defaultValue);

  const renderIcon = (value: string) => {
    switch (iconName) {
      case ICON_TYPE.WORKING_SHIFTS:
        return <WorkingShiftIcon id={Number(value)} />;
      case ICON_TYPE.PRIORITY:
        return <PriorityIcon id={Number(value)} />;
      default:
        return null;
    }
  };

  return (
    <div className={classNames('new-select', classNameWrapper)}>
      {!!label && <label className="new-select__label">{label}</label>}
      <Select
        // @ts-ignore
        styles={customStyles(disabled || hideIndicator, errorBorder, typeColor)}
        className={classNames('new-select__select', className)}
        options={innerPreparedOptions}
        value={_defaultValue}
        name={name}
        onChange={newValue => onChange(newValue, name || '')}
        placeholder={placeholder}
        isLoading={loading}
        isSearchable={isSearchable}
        // @ts-ignore
        isMulti={multi}
        isClearable={useClearable}
        openMenuOnClick={openMenuOnClick}
        closeMenuOnSelect={!multi}
        hideSelectedOptions={false}
        controlShouldRenderValue={!hiddenValue}
        menuIsOpen={hiddenValue}
        menuShouldScrollIntoView={false}
        isDisabled={disabled || loading}
        openMenuOnFocus={true}
        isOptionDisabled={option => !!option.disabled}
        components={{
          DropdownIndicator: props => {
            if (disabled || hideIndicator) {
              return null;
            } else {
              return (
                <components.DropdownIndicator {...props} className="shevron_icon">
                  <Chevron />
                </components.DropdownIndicator>
              );
            }
          },
          ClearIndicator: props => (
            <components.ClearIndicator {...props} className="CLEAR">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4967 5.27711 12.8108 3.62573 11.5925 2.40746C10.3743 1.18918 8.72289 0.503304 7 0.5ZM9.35625 8.64375C9.45014 8.73855 9.50282 8.86658 9.50282 9C9.50282 9.13342 9.45014 9.26145 9.35625 9.35625C9.26068 9.44866 9.13294 9.50032 9 9.50032C8.86706 9.50032 8.73932 9.44866 8.64375 9.35625L7 7.70625L5.35625 9.35625C5.26068 9.44866 5.13294 9.50032 5 9.50032C4.86706 9.50032 4.73932 9.44866 4.64375 9.35625C4.54986 9.26145 4.49719 9.13342 4.49719 9C4.49719 8.86658 4.54986 8.73855 4.64375 8.64375L6.29375 7L4.64375 5.35625C4.56402 5.2591 4.52328 5.13577 4.52944 5.01024C4.53561 4.88472 4.58824 4.76597 4.67711 4.67711C4.76598 4.58824 4.88472 4.5356 5.01025 4.52944C5.13577 4.52327 5.2591 4.56402 5.35625 4.64375L7 6.29375L8.64375 4.64375C8.7409 4.56402 8.86423 4.52327 8.98976 4.52944C9.11528 4.5356 9.23403 4.58824 9.3229 4.67711C9.41177 4.76597 9.4644 4.88472 9.47057 5.01024C9.47673 5.13577 9.43598 5.2591 9.35625 5.35625L7.70625 7L9.35625 8.64375Z"
                  fill="#AFAFAF"
                />
              </svg>
            </components.ClearIndicator>
          ),
          Option: props => (
            <components.Option {...props}>
              {iconName && <div className="new-select__icon">{renderIcon(props.data.value)}</div>}
              <div className="new-select__value">{props.data.label}</div>
              {props.isMulti && props.isSelected && (
                <div className="new-select__check">
                  <Check />
                </div>
              )}
            </components.Option>
          ),
          SingleValue: ({ children, ...props }) => (
            <components.SingleValue {...props}>
              {iconName && <div className="new-select__icon">{renderIcon(props.data.value)}</div>}
              <div className="new-select__value">{children}</div>
            </components.SingleValue>
          ),
          MultiValue: ({ children, ...props }) => {
            return (
              <div className="new-select__multiValue" {...props}>
                {children}
              </div>
            );
          },
        }}
      />
      {error && <div className="new-select__error">{error}</div>}
    </div>
  );
};

export default SelectsField;
