import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWorker, selectProfile } from '~/redux/selectors/usersSelectors';
import { Spoiler } from '~/components';
import { ReactComponent as ArrowRightSvg } from '~/assets/arrow-right.svg';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { generatePassword } from '~/utils';
import { selectProfessions } from '~/redux/selectors/professionsSelectors';
import { selectRoles } from '~/redux/selectors/rolesSelectors';
import { selectDepartments } from '~/redux/selectors/departmentsSelectors';
import { selectScheduleTemplates } from '~/redux/selectors/scheduleTemplatesSelectors';
import {
  getLocalHours,
  getLocalMinutes,
  getLocalTimeSchedule,
  getServerTime,
  prepareDateServerTime,
} from '~/helpers/convertToUnix';
import { createUser, updateProfile, updateUser } from '~/redux/reducers/GetUsersThunk';
import Api, { EShiftStatus } from 'sb_manufacturing_front_api';
import { toast } from 'react-toastify';
import { signOut } from '~/redux/modules/authModule';
import { closePopup, openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { selectCurrentShift } from '~/redux/selectors/stateSelectors';
import {
  COLORS,
  INPUT_TYPE,
  PASSWORD,
  PLACEHOLDER,
  TYPE_ROLE,
  NAMES_FIELD,
  ALERT_TYPE,
  ALERT,
} from '~/helpers/constants';
import Popup from '~/components/popup/Popup';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import SelectsField from '~/components/form/select/Select';
import Button from '~/components/form/buttons/Button';
import InputsField from '~/components/form/input/Input';
import onInput from '~/utils/onInput';
import { ValidationSubmit } from '~/utils/validations';
import { ScheduleTemplateInfo } from '~/components/schedule/popup/schedule-template-info';
import { showAlert } from '~/redux/modules/stateModule';
import getTimeOptions from '~/helpers/getTimeOptions';

const TYPE_POPUP = {
  CREATE: 'create',
  EDIT_PROFILE: 'edit_profile',
  EDIT_USER: 'edit_user',
};

const preparedRanks: ISelectOption = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
};

interface IWorkerPopup {
  userId: number;
}

const WorkerPopup = ({ userId }: IWorkerPopup) => {
  const professionsValues: ISelectOption = {};
  const departmentValues: ISelectOption = {};
  const roleValues: ISelectOption = {};
  const scheduleTemplateValues: ISelectOption = {};

  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const currentWorker = useSelector(selectCurrentWorker(userId));
  const currentShift = useSelector(selectCurrentShift);
  const companyOptions = useSelector(selectCompanyOptions);
  const getTime = getTimeOptions();

  const isProfile = currentWorker?.id === profile?.id;
  const typePopup = currentWorker
    ? isProfile
      ? TYPE_POPUP.EDIT_PROFILE
      : TYPE_POPUP.EDIT_USER
    : TYPE_POPUP.CREATE;
  const isCreateUserPopup = typePopup === TYPE_POPUP.CREATE;

  useSelector(selectProfessions).forEach(v => (professionsValues[v.id] = v.name));
  useSelector(selectRoles)
    .filter(role => [TYPE_ROLE.SUPERVISOR, TYPE_ROLE.WORKER].includes(role.id))
    .forEach(r => (roleValues[r.id] = r.name));
  useSelector(selectDepartments)
    .filter(department => profile?.departments_ids.includes(department.id))
    .forEach(v => (departmentValues[v.id] = v.name));
  useSelector(selectScheduleTemplates).forEach(t => (scheduleTemplateValues[t.id] = t.name));

  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const [state, setState] = React.useState<IStateForm>({
    [NAMES_FIELD.LAST_NAME]: currentWorker?.lastname,
    [NAMES_FIELD.ID]: String(currentWorker?.id),
    [NAMES_FIELD.FIRST_NAME]: currentWorker?.firstname,
    [NAMES_FIELD.MIDDLE_NAME]: currentWorker?.middlename,
    [NAMES_FIELD.LOGIN]: currentWorker?.login,
    [NAMES_FIELD.DEPARTMENTS]: currentWorker?.departments,
    [NAMES_FIELD.VIEW_DEPARTMENTS]: currentWorker?.view_departments,
    [NAMES_FIELD.ROLE_NAME]: currentWorker?.roleName,
    [NAMES_FIELD.PROFESSION_ID]: currentWorker?.profession_id,
    [NAMES_FIELD.ROLE]: currentWorker?.role_id,
    [NAMES_FIELD.DEPARTMENT_ID]: currentWorker?.departments_ids?.[0],
    [NAMES_FIELD.CATEGORY]: currentWorker?.category,
    [NAMES_FIELD.SCHEDULE_TEMPLATE_END_DATE]: getLocalTimeSchedule(
      currentWorker?.schedule_template_end_date,
      companyOptions.time_zone,
    ),
    [NAMES_FIELD.SCHEDULE_TEMPLATE_START_DATE]: getLocalTimeSchedule(
      currentWorker?.schedule_template_start_date,
      companyOptions.time_zone,
    ),
    [`${NAMES_FIELD.DAY_SHIFT_START_TIME}_hours`]: getLocalHours(
      currentWorker?.day_shift_start_time ?? '',
      companyOptions.time_zone,
    ),
    [`${NAMES_FIELD.DAY_SHIFT_START_TIME}_minutes`]: getLocalMinutes(
      currentWorker?.day_shift_start_time ?? '',
      companyOptions.time_zone,
    ),
    [`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_hours`]: getLocalHours(
      currentWorker?.night_shift_start_time ?? '',
      companyOptions.time_zone,
    ),
    [`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_minutes`]: getLocalMinutes(
      currentWorker?.night_shift_start_time ?? '',
      companyOptions.time_zone,
    ),
  });
  const [errors, setErrors] = React.useState<{ [name: string]: string }>({});
  const userWorking = currentWorker?.is_fired !== 1;

  React.useEffect(() => {
    switch (typePopup) {
      case TYPE_POPUP.CREATE:
        setState(prevState => {
          return {
            ...prevState,
            [NAMES_FIELD.PASSWORD_WORKER]: '',
            [NAMES_FIELD.SCHEDULE_TEMPLATE_ID]: currentWorker?.schedule_template_id,
            [NAMES_FIELD.SCHEDULE_TEMPLATE_START_DATE]: getLocalTimeSchedule(
              currentWorker?.schedule_template_start_date,
              companyOptions.time_zone,
            ),
          };
        });

        setErrors(prevState => {
          return {
            ...prevState,
            [NAMES_FIELD.LAST_NAME]: '',
            [NAMES_FIELD.FIRST_NAME]: '',
            [NAMES_FIELD.PROFESSION_ID]: '',
            [NAMES_FIELD.CATEGORY]: '',
            [NAMES_FIELD.DEPARTMENT_ID]: '',
            [NAMES_FIELD.ROLE]: '',
            [NAMES_FIELD.PASSWORD_WORKER]: '',
          };
        });
        break;
      case TYPE_POPUP.EDIT_USER:
        setState(prevState => {
          return {
            ...prevState,
            [NAMES_FIELD.SCHEDULE_TEMPLATE_ID]: currentWorker?.schedule_template_id,
            [NAMES_FIELD.SCHEDULE_TEMPLATE_START_DATE]: getLocalTimeSchedule(
              currentWorker?.schedule_template_start_date,
              companyOptions.time_zone,
            ),
            [`${NAMES_FIELD.DAY_SHIFT_START_TIME}_hours`]: getLocalHours(
              currentWorker?.day_shift_start_time ?? '',
              companyOptions.time_zone,
            ),
            [`${NAMES_FIELD.DAY_SHIFT_START_TIME}_minutes`]: getLocalMinutes(
              currentWorker?.day_shift_start_time ?? '',
              companyOptions.time_zone,
            ),
            [`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_hours`]: getLocalHours(
              currentWorker?.night_shift_start_time ?? '',
              companyOptions.time_zone,
            ),
            [`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_minutes`]: getLocalMinutes(
              currentWorker?.night_shift_start_time ?? '',
              companyOptions.time_zone,
            ),
          };
        });
        break;
    }
  }, [typePopup]);

  const handleSubmit = async () => {
    ValidationSubmit({
      state,
      setErrors,
    });

    if (Object.keys(errors).length !== 0) return;

    setIsSubmitted(true);

    let result: any;
    let toastText = 'Данные обновлены';

    const values = {
      id: Number(state[NAMES_FIELD.ID]),
      lastname: state[NAMES_FIELD.LAST_NAME],
      firstname: state[NAMES_FIELD.FIRST_NAME],
      middlename: state[NAMES_FIELD.MIDDLE_NAME],
      profession_id: state[NAMES_FIELD.PROFESSION_ID],
      department_id: state[NAMES_FIELD.DEPARTMENT_ID],
      login: state[NAMES_FIELD.LOGIN],
      password: state[NAMES_FIELD.PASSWORD_WORKER],
      category: state[NAMES_FIELD.CATEGORY],
      role: state[NAMES_FIELD.ROLE],
      schedule_template_id: state[NAMES_FIELD.SCHEDULE_TEMPLATE_ID],
      schedule_template_start_date: prepareDateServerTime(
        state[NAMES_FIELD.SCHEDULE_TEMPLATE_START_DATE] || '',
        companyOptions.time_zone,
      ),
      schedule_template_end_date: prepareDateServerTime(
        state[NAMES_FIELD.SCHEDULE_TEMPLATE_END_DATE] || '',
        companyOptions.time_zone,
      ),
      day_shift_start_time: getServerTime(
        state[`${NAMES_FIELD.DAY_SHIFT_START_TIME}_hours`] ?? '00',
        state[`${NAMES_FIELD.DAY_SHIFT_START_TIME}_minutes`] ?? '00',
        companyOptions.time_zone,
      ),
      night_shift_start_time: getServerTime(
        state[`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_hours`] ?? '00',
        state[`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_minutes`] ?? '00',
        companyOptions.time_zone,
      ),
    };

    if (isProfile) {
      delete values.password;
      result = await dispatch(updateProfile(values));
    } else if (!isCreateUserPopup) {
      delete values.password;
      result = await dispatch(updateUser(values));
    } else {
      result = await dispatch(createUser(values));
      toastText = 'Пользователь создан';
    }

    if (result?.payload?.kind === 'ok') {
      toast.success(toastText);
      dispatch(closePopup());
    }

    setIsSubmitted(false);
  };

  const handleFireUser = async () => {
    const data = {
      title: 'Уволить работника',
      description: `${state[NAMES_FIELD.LAST_NAME]} ${
        state[NAMES_FIELD.FIRST_NAME]
      } будет уволен. Продолжить?`,
      name: ALERT.DISMISS_USER,
      type: ALERT_TYPE.DISMISS,
      data: {
        userId: state[NAMES_FIELD.ID],
      },
    };
    dispatch(showAlert(data));
  };

  const handleRecoverUser = async () => {
    const data = {
      title: 'Восстановить работника',
      description: `${state[NAMES_FIELD.LAST_NAME]} ${
        state[NAMES_FIELD.FIRST_NAME]
      } будет восстановлен. Продолжить?`,
      name: ALERT.RESTORE_USER,
      type: ALERT_TYPE.RESTORE,
      data: {
        userId: state[NAMES_FIELD.ID],
      },
    };
    dispatch(showAlert(data));
  };

  const handleClickExit = async () => {
    if (profile?.role_id === TYPE_ROLE.SUPERVISOR) {
      await Api.getCurrentTask().then(response => {
        if (response.kind === 'ok') {
          if (response.data) {
            const data = {
              title: 'Завершите задачу',
              description: 'Вы не можете выйти, так как у вас осталась незавершенная задача.',
              name: ALERT.END_SHIFT,
              type: ALERT_TYPE.CLEAR,
            };

            dispatch(showAlert(data));
          } else {
            if (currentShift && currentShift?.status === EShiftStatus.IN_PROGRESS) {
              const data = {
                title: 'Закончите смену',
                description:
                  'Вы не можете выйти, так как у вас не закончена смена. Закончить смену?',
                name: ALERT.END_SHIFT,
                type: ALERT_TYPE.FINISH,
                data: {
                  shiftId: currentShift?.id,
                },
              };

              dispatch(showAlert(data));
            } else {
              dispatch(signOut());
              dispatch(closePopup());
            }
          }
        }
      });
    } else {
      dispatch(signOut());
      dispatch(closePopup());
    }
  };

  const handelOnInput = (value: string, name: string) => {
    onInput({
      value,
      name,
      state,
      setState,
      setErrors,
    });
  };

  return (
    <Popup aside>
      <AsidePopupHeader>
        <>
          {isProfile && (
            <button
              className="aside-popup__header-button aside-popup__header-button_log-out"
              onClick={handleClickExit}
            >
              Выход
              <ArrowRightSvg />
            </button>
          )}
        </>
      </AsidePopupHeader>
      <AsidePopupBody>
        <>
          <div className="user-profile__info">
            {!isCreateUserPopup && (
              <div className="user-avatar user-avatar_popup">
                {currentWorker?.lastname?.charAt(0).toUpperCase()}
                {currentWorker?.firstname?.charAt(0).toUpperCase()}
              </div>
            )}
            <div className="user-profile__text">
              <h2 className="user-profile__name">
                {isCreateUserPopup
                  ? 'Новый сотрудник'
                  : `${currentWorker?.lastname} ${currentWorker?.firstname
                      ?.charAt(0)
                      .toUpperCase()}.`}
              </h2>
              {!isCreateUserPopup && (
                <span className="user-profile__position">{currentWorker?.professionName}</span>
              )}
            </div>
          </div>
          <Spoiler
            title="Личные данные"
            open={
              !!errors[NAMES_FIELD.LAST_NAME] ||
              !!errors[NAMES_FIELD.FIRST_NAME] ||
              !!errors[NAMES_FIELD.PASSWORD_WORKER]
            }
          >
            <div className="user-profile__block">
              <InputsField
                name={NAMES_FIELD.LAST_NAME}
                label="Фамилия"
                placeholder="Иванов"
                errorBorder={!!errors[NAMES_FIELD.LAST_NAME]}
                error={errors[NAMES_FIELD.LAST_NAME]}
                onInput={(value, name) => handelOnInput(value, name)}
                defaultValue={state[NAMES_FIELD.LAST_NAME]}
              />
            </div>
            <div className="user-profile__block">
              <InputsField
                name={NAMES_FIELD.FIRST_NAME}
                label="Имя"
                placeholder="Иван"
                errorBorder={!!errors[NAMES_FIELD.FIRST_NAME]}
                error={errors[NAMES_FIELD.FIRST_NAME]}
                onInput={(value, name) => handelOnInput(value, name)}
                defaultValue={state[NAMES_FIELD.FIRST_NAME]}
              />
            </div>
            <div className="user-profile__block">
              <InputsField
                name={NAMES_FIELD.MIDDLE_NAME}
                label="Отчество"
                placeholder="Ивананович"
                onInput={(value, name) => handelOnInput(value, name)}
                defaultValue={state[NAMES_FIELD.MIDDLE_NAME]}
              />
            </div>
            {isCreateUserPopup && (
              <div className="user-profile__block">
                <InputsField
                  name={NAMES_FIELD.PASSWORD_WORKER}
                  label="Пароль"
                  placeholder="Придумайте пароль"
                  errorBorder={!!errors[NAMES_FIELD.PASSWORD_WORKER]}
                  error={errors[NAMES_FIELD.PASSWORD_WORKER]}
                  defaultValue={state[NAMES_FIELD.PASSWORD_WORKER]}
                  onInput={(value, name) => handelOnInput(value, name)}
                  type={INPUT_TYPE.PASSWORD}
                />
                <div
                  className="workerPopupGeneratePassword"
                  onClick={() =>
                    handelOnInput(
                      generatePassword(companyOptions?.use_strong_security),
                      NAMES_FIELD.PASSWORD_WORKER,
                    )
                  }
                >
                  Сгенерировать пароль
                </div>
              </div>
            )}
          </Spoiler>
          <Spoiler
            title="Заводские данные"
            open={
              !!errors[NAMES_FIELD.PROFESSION_ID] ||
              !!errors[NAMES_FIELD.CATEGORY] ||
              !!errors[NAMES_FIELD.DEPARTMENT_ID] ||
              !!errors[NAMES_FIELD.ROLE] ||
              !!errors[NAMES_FIELD.LOGIN]
            }
          >
            <div className="user-profile__block">
              <SelectsField
                name={NAMES_FIELD.PROFESSION_ID}
                label="Профессия"
                options={professionsValues}
                errorBorder={!!errors[NAMES_FIELD.PROFESSION_ID]}
                error={errors[NAMES_FIELD.PROFESSION_ID]}
                onChange={(value, name) => handelOnInput(value!.value, name)}
                defaultValue={state[NAMES_FIELD.PROFESSION_ID]}
                placeholder={PLACEHOLDER.NONE}
                disabled={isProfile}
              />
            </div>
            <div className="user-profile__block">
              <SelectsField
                name={NAMES_FIELD.CATEGORY}
                label="Разряд"
                options={preparedRanks}
                onChange={(value, name) => handelOnInput(value!.value, name)}
                errorBorder={!!errors[NAMES_FIELD.CATEGORY]}
                error={errors[NAMES_FIELD.CATEGORY]}
                defaultValue={state[NAMES_FIELD.CATEGORY]}
                placeholder={PLACEHOLDER.NONE}
                disabled={isProfile}
              />
            </div>
            <div className="user-profile__block">
              <InputsField
                name={NAMES_FIELD.LOGIN}
                label="Логин"
                placeholder="Придумайте пароль"
                errorBorder={!!errors[NAMES_FIELD.LOGIN]}
                error={errors[NAMES_FIELD.LOGIN]}
                onInput={(value, name) => handelOnInput(value, name)}
                defaultValue={state[NAMES_FIELD.LOGIN]}
                disabled={isProfile}
              />
            </div>
            <div className="user-profile__block">
              {isProfile ? (
                <InputsField
                  label="Цеха работы"
                  placeholder=""
                  defaultValue={state[NAMES_FIELD.DEPARTMENTS]}
                  onInput={(value, name) => handelOnInput(value, name)}
                  disabled
                />
              ) : (
                <SelectsField
                  name={NAMES_FIELD.DEPARTMENT_ID}
                  label="Цех"
                  options={departmentValues}
                  onChange={(value, name) => handelOnInput(value!.value, name)}
                  errorBorder={!!errors[NAMES_FIELD.DEPARTMENT_ID]}
                  error={errors[NAMES_FIELD.DEPARTMENT_ID]}
                  defaultValue={state[NAMES_FIELD.DEPARTMENT_ID]}
                  placeholder={PLACEHOLDER.NONE}
                  disabled={isProfile}
                />
              )}
            </div>
            <div className="user-profile__block">
              {isProfile && (
                <InputsField
                  label="Цеха просмотра"
                  placeholder=""
                  defaultValue={state[NAMES_FIELD.VIEW_DEPARTMENTS]}
                  onInput={(value, name) => handelOnInput(value, name)}
                  disabled
                />
              )}
            </div>
            <div className="user-profile__block">
              {isProfile ? (
                <InputsField
                  label="Роль"
                  placeholder=""
                  defaultValue={state[NAMES_FIELD.ROLE_NAME]}
                  onInput={(value, name) => handelOnInput(value, name)}
                  disabled
                />
              ) : (
                <SelectsField
                  name={NAMES_FIELD.ROLE}
                  label="Роль"
                  options={roleValues}
                  onChange={(value, name) => handelOnInput(value!.value, name)}
                  errorBorder={!!errors[NAMES_FIELD.ROLE]}
                  error={errors[NAMES_FIELD.ROLE]}
                  defaultValue={state[NAMES_FIELD.ROLE]}
                  placeholder={PLACEHOLDER.NONE}
                  disabled={isProfile}
                />
              )}
            </div>
          </Spoiler>
          {!isProfile && (
            <Spoiler title="График" open={!!errors[NAMES_FIELD.SCHEDULE_TEMPLATE_ID]}>
              {companyOptions.use_schedule_template && (
                <div>
                  <div className="user-profile__block">
                    <SelectsField
                      name={
                        [TYPE_POPUP.CREATE, TYPE_POPUP.EDIT_USER].includes(typePopup)
                          ? NAMES_FIELD.SCHEDULE_TEMPLATE_ID
                          : ''
                      }
                      label="Шаблон смен"
                      options={scheduleTemplateValues}
                      onChange={(value, name) => handelOnInput(value?.value || '', name)}
                      errorBorder={!!errors[NAMES_FIELD.SCHEDULE_TEMPLATE_ID]}
                      error={errors[NAMES_FIELD.SCHEDULE_TEMPLATE_ID]}
                      defaultValue={state[NAMES_FIELD.SCHEDULE_TEMPLATE_ID]}
                      placeholder={PLACEHOLDER.NONE}
                    />
                  </div>
                  <div className="user-profile__block">
                    <ScheduleTemplateInfo
                      dateStart={state[NAMES_FIELD.SCHEDULE_TEMPLATE_START_DATE]}
                      dateEnd={state[NAMES_FIELD.SCHEDULE_TEMPLATE_END_DATE]}
                      setDateEnd={(value, name) => handelOnInput(value, name)}
                      setDateStart={(value, name) => handelOnInput(value, name)}
                      nameStart={NAMES_FIELD.SCHEDULE_TEMPLATE_START_DATE}
                      nameEnd={NAMES_FIELD.SCHEDULE_TEMPLATE_END_DATE}
                      errorStart={errors[NAMES_FIELD.SCHEDULE_TEMPLATE_START_DATE]}
                      errorEnd={errors[NAMES_FIELD.SCHEDULE_TEMPLATE_END_DATE]}
                    />
                  </div>
                </div>
              )}
              {!companyOptions.use_schedule_template && (
                <div>
                  <div className="user-profile__block">
                    <div className="time-inputs">
                      <h3 className="time-inputs__title">{'Время начала дневной смены'}</h3>
                      <div className="time-inputs__item">
                        <SelectsField
                          name={`${NAMES_FIELD.DAY_SHIFT_START_TIME}_hours`}
                          options={getTime.hours}
                          onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                          defaultValue={state[`${NAMES_FIELD.DAY_SHIFT_START_TIME}_hours`]}
                          hideIndicator
                        />
                      </div>
                      <div className="time-inputs__item">
                        <SelectsField
                          name={`${NAMES_FIELD.DAY_SHIFT_START_TIME}_minutes`}
                          options={getTime.minutes}
                          onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                          defaultValue={state[`${NAMES_FIELD.DAY_SHIFT_START_TIME}_minutes`]}
                          hideIndicator
                        />
                      </div>
                    </div>
                  </div>
                  <div className="user-profile__block">
                    <div className="time-inputs">
                      <h3 className="time-inputs__title">{'Время начала ночной смены'}</h3>
                      <div className="time-inputs__item">
                        <SelectsField
                          name={`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_hours`}
                          options={getTime.hours}
                          onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                          defaultValue={state[`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_hours`]}
                          hideIndicator
                        />
                      </div>
                      <div className="time-inputs__item">
                        <SelectsField
                          name={`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_minutes`}
                          options={getTime.minutes}
                          onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                          defaultValue={state[`${NAMES_FIELD.NIGHT_SHIFT_START_TIME}_minutes`]}
                          hideIndicator
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Spoiler>
          )}
          {userWorking && (
            <div className="user-profile__block">
              <Button
                text="Сохранить"
                onClick={handleSubmit}
                disabled={isSubmitted}
                background={COLORS.BLUE}
              />
            </div>
          )}
          {isProfile && (
            <div
              className="text_pointer user-profile__btn-change"
              onClick={async () => {
                await dispatch(closePopup());
                dispatch(
                  openPopup({
                    name: POPUPS_NAME.SET_PASSWORD_POPUP,
                    data: { type: PASSWORD.CHANGE },
                  }),
                );
              }}
            >
              Сменить пароль
            </div>
          )}
          {!isProfile && !userWorking && (
            <div
              className="text_blue text_fs20 text_pointer text_mt25"
              style={{ marginTop: '15px', width: 'max-content' }}
              onClick={handleRecoverUser}
            >
              Восстановить
            </div>
          )}
          {!isCreateUserPopup && !isProfile && userWorking && (
            <div
              className="text_blue text_fs20 text_pointer text_mt25"
              onClick={async () => {
                await dispatch(closePopup());
                dispatch(
                  openPopup({
                    name: POPUPS_NAME.GENERATOR_PASSWORD_POPUP,
                    data: { userId: currentWorker?.id },
                  }),
                );
              }}
            >
              Сгенерировать новый пароль
            </div>
          )}
          {!isCreateUserPopup && !isProfile && userWorking && (
            <div
              className="text_red text_fs20 text_pointer text_mt25"
              style={{ marginTop: '15px', width: 'max-content' }}
              onClick={handleFireUser}
            >
              Уволить
            </div>
          )}
        </>
      </AsidePopupBody>
    </Popup>
  );
};

export default WorkerPopup;
