import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import { removeShift, updateRawShift, updateShift } from '~/redux/modules/shiftsModule';
import { ALERT_TYPE, ALERT } from '~/helpers/constants';
import { removeTasks, updateTaskStatus } from '~/redux/modules/tasksModule';
import { deleteTaskTimeless } from '~/redux/modules/tasksTimelessModule';
import { fireUser, recoverUser } from '~/redux/reducers/GetUsersThunk';
import { removetaskTimelessTemplates } from '~/redux/modules/tasksTimelessTemplatesModule';
import { removeTaskTemplate } from '~/redux/modules/tasksTemplatesModule';
import Api from 'sb_manufacturing_front_api';
import { hideAlert } from '~/redux/modules/stateModule';
import { closePopup } from '~/redux/modules/popupModule';

interface IAlertDialog {
  alert?: IAlertData;
}

const Alert = ({ alert }: IAlertDialog) => {
  const dispatch = useDispatch();

  const onDismiss = () => {
    dispatch(hideAlert());
  };

  const onAccept = async () => {
    switch (alert?.name) {
      case ALERT.REMOVE_USER_SHIFTS:
        if (alert?.data && alert.data.shiftId && alert.data.userName) {
          dispatch(removeShift({ shiftId: alert.data.shiftId, name: alert.data.userName }));
        }
        break;
      case ALERT.REMOVE_TASK:
        if (alert?.data && alert.data.taskId) {
          dispatch(removeTasks(alert.data.taskId));
        }
        break;
      case ALERT.REMOVE_TASK_TIMELESS:
        if (alert?.data && alert.data.taskId) {
          dispatch(deleteTaskTimeless(alert.data.taskId));
        }
        break;
      case ALERT.REMOVE_SHIFT:
        if (alert?.data && alert.data.shiftId) {
          dispatch(removeShift({ shiftId: alert.data.shiftId }));
        }
        break;
      case ALERT.CHANGE_RAW_SHIFT:
        dispatch(updateRawShift(alert.data?.shiftData as IStateForm));
        break;
      case ALERT.CHANGE_SHIFT:
        if (
          alert?.data &&
          alert.data.shiftId &&
          alert.data.userId &&
          alert.data.startTime &&
          alert.data.workShiftId
        ) {
          dispatch(
            updateShift({
              shiftId: alert.data.shiftId,
              userId: alert.data.userId,
              start_time: alert.data.startTime,
              workShiftId: alert.data.workShiftId,
            }),
          );
        }
        break;
      case ALERT.REMOVE_TEMPLATE:
        if (alert?.data && alert.data.taskId) {
          if (alert.data.isDuration) {
            dispatch(removetaskTimelessTemplates(alert.data.taskId));
          } else {
            dispatch(removeTaskTemplate(alert.data.taskId));
          }
        }
        break;
      case ALERT.DISMISS_USER:
        if (alert?.data && alert.data.userId) {
          dispatch(closePopup());
          dispatch(fireUser(alert.data.userId));
        }
        break;
      case ALERT.RESTORE_USER:
        if (alert?.data && alert.data.userId) {
          dispatch(closePopup());
          dispatch(recoverUser(alert.data.userId));
        }
        break;
      case ALERT.END_SHIFT:
        if (alert?.data && alert.data.shiftId) {
          await Api.checkOut({
            shiftId: alert.data.shiftId,
            location: { lat: 0, lng: 0 },
          });
        }
        break;
      case ALERT.START_TASK:
        if (alert?.data && alert.data.taskId && alert.data.taskStatus) {
          dispatch(
            updateTaskStatus({
              id: alert.data.taskId,
              status: alert.data.taskStatus,
              comment: alert.data.taskComment,
              images: alert.data.taskImages,
            }),
          );
        }
        break;
      default:
        break;
    }
    dispatch(hideAlert());
  };

  const generateButtons = (type: string | undefined) => {
    switch (type) {
      case ALERT_TYPE.REMOVE:
        return (
          <>
            <button onClick={onDismiss}>Отмена</button>
            <button className="delete" onClick={onAccept}>
              Удалить
            </button>
          </>
        );
      case ALERT_TYPE.DISMISS:
        return (
          <>
            <button onClick={onDismiss}>Отмена</button>
            <button className="delete" onClick={onAccept}>
              Уволить
            </button>
          </>
        );
      case ALERT_TYPE.RESTORE:
        return (
          <>
            <button onClick={onDismiss}>Отмена</button>
            <button onClick={onAccept}>Восстановить</button>
          </>
        );
      case ALERT_TYPE.CHANGE:
        return (
          <>
            <button onClick={onDismiss}>Отмена</button>
            <button onClick={onAccept}>Изменить</button>
          </>
        );
      case ALERT_TYPE.CLEAR:
        return (
          <>
            <button onClick={onDismiss}>Ок</button>
          </>
        );
      case ALERT_TYPE.FINISH:
        return (
          <>
            <button onClick={onDismiss}>Отмена</button>
            <button onClick={onAccept}>Закончить</button>
          </>
        );
      default:
        return (
          <>
            <button onClick={onDismiss}>Нет</button>
            <button onClick={onAccept}>Да</button>
          </>
        );
    }
  };

  return (
    <div className="alert alert__active">
      <div className="alert__bg" onClick={onDismiss} />
      <div className="alert-dialog">
        <div className="alert-dialog__content">
          <div className="alert-dialog__header">
            <p>{alert?.title}</p>
            <button className="alert-dialog__close" onClick={onDismiss}>
              <CloseSvg />
            </button>
          </div>
          <div className="alert-dialog__text">
            <p>{alert?.description}</p>
          </div>
        </div>
        <div className="alert-dialog__buttons">{generateButtons(alert?.type)}</div>
      </div>
    </div>
  );
};

export default Alert;
