import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { EShiftType, INewShift } from 'sb_manufacturing_front_api';
import { addUserRawShift, addUserShift } from '~/redux/modules/shiftsModule';
import { selectWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { getServerTime, prepareDateServerTime } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { FORMAT_MOMENT, workingShiftInfo, COLORS, ALERT, ALERT_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import { showAlert } from '~/redux/modules/stateModule';

interface IWorkShiftInfoActionsProps {
  workerId?: number;
  shift?: INewShift;
  workShiftDate: string;
  isStartedCreatingShift: boolean;
  workShiftId?: number;
  handleClosePopup: () => void;
  formData: IStateForm;
  useScheduleTemplate: boolean;
}

export const prepareShiftData = (
  formData: IStateForm,
  timeZone: number,
  workerId: number,
  workShiftDay: Moment,
  shiftId?: number,
) => {
  let dinnerEndTime: string | null;
  let dinnerStartTime: string | null;
  const shiftStartTime = getServerTime(
    formData.shift_start_hour,
    formData.shift_start_minutes,
    timeZone,
    workShiftDay.clone(),
  );
  let shiftEndTime = getServerTime(
    formData.shift_end_hour,
    formData.shift_end_minutes,
    timeZone,
    workShiftDay.clone(),
  );
  if (
    moment(shiftEndTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS).isSameOrBefore(
      moment(shiftStartTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    )
  ) {
    console.log('move end date', shiftEndTime);
    shiftEndTime = moment(shiftEndTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS)
      .add(1, 'days')
      .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
    console.log(shiftEndTime);
  }
  if (Boolean(formData.has_dinner)) {
    dinnerStartTime = getServerTime(
      formData.dinner_start_hour,
      formData.dinner_start_minutes,
      timeZone,
      workShiftDay.clone(),
    );
    if (
      moment(dinnerStartTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS).isBefore(
        moment(shiftStartTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS),
      )
    ) {
      console.log('move end date dinnerStartTime', dinnerStartTime);
      dinnerStartTime = moment(dinnerStartTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS)
        .add(1, 'days')
        .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
      console.log(dinnerStartTime);
    }
    dinnerEndTime = getServerTime(
      formData.dinner_end_hour,
      formData.dinner_end_minutes,
      timeZone,
      workShiftDay.clone(),
    );
    if (
      moment(dinnerEndTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS).isBefore(
        moment(shiftStartTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS),
      )
    ) {
      dinnerEndTime = moment(dinnerEndTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS)
        .add(1, 'days')
        .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
    }
  } else {
    dinnerEndTime = null;
    dinnerStartTime = null;
  }

  return {
    shift_start_time: shiftStartTime,
    shift_end_time: shiftEndTime,
    dinner_start_time: dinnerStartTime,
    dinner_end_time: dinnerEndTime,
    users_ids: [workerId],
    has_dinner: formData.has_dinner,
    timeZone: timeZone,
    shift_id: shiftId,
  };
};

export const WorkShiftInfoActions = ({
  workerId,
  shift,
  workShiftDate,
  isStartedCreatingShift,
  workShiftId,
  formData,
  useScheduleTemplate,
}: IWorkShiftInfoActionsProps) => {
  const dispatch = useDispatch();
  const workingShifts = useSelector(selectWorkingShifts);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const workShiftDay = moment(workShiftDate, FORMAT_MOMENT.DASH_YYYYMMDD);
  const shiftStartTime = (workShiftId: number) => {
    const workingShift = workingShifts.find(item => item.id === workShiftId);
    const beginTime = moment(workingShift?.begin_time, FORMAT_MOMENT.HHMMSS).add(timeZone, 'hours');

    workShiftDay.hours(moment(beginTime).hours());
    workShiftDay.minutes(moment(beginTime).minutes());
    workShiftDay.seconds(moment(beginTime).seconds());

    return prepareDateServerTime(workShiftDay.format(FORMAT_MOMENT.YYYYMMDD_HHMMSS), timeZone);
  };

  const onCreate = React.useCallback(() => {
    if (useScheduleTemplate) {
      if (workShiftId && workerId) {
        dispatch(
          addUserShift({
            workShiftId,
            start_time: shiftStartTime(workShiftId),
            userId: workerId,
          }),
        );
      }
    } else {
      if (workerId) {
        dispatch(addUserRawShift(prepareShiftData(formData, timeZone, workerId, workShiftDay)));
      }
    }
  }, [workShiftId, formData, workerId]);

  const onUpdate = React.useCallback(() => {
    console.log('here', useScheduleTemplate, shift, workShiftId, workerId);
    let data = null;
    if (useScheduleTemplate) {
      if (shift && workShiftId && workerId) {
        data = {
          title: 'Изменение смены',
          description: 'Вы действительно хотите изменить смену?',
          name: ALERT.CHANGE_SHIFT,
          type: ALERT_TYPE.CHANGE,
          data: {
            shiftId: shift.id,
            userId: workerId,
            startTime: shiftStartTime(workShiftId),
            workShiftId: workShiftId,
          },
        };
      }
    } else {
      if (shift && workerId) {
        data = {
          title: 'Изменение смены',
          description: 'Вы действительно хотите изменить смену?',
          name: ALERT.CHANGE_RAW_SHIFT,
          type: ALERT_TYPE.CHANGE,
          data: {
            shiftData: prepareShiftData(formData, timeZone, workerId, workShiftDay, shift.id),
          },
        };
        console.log(data);
      }
    }

    console.log('sdfsdf');
    console.log(data);

    if (data) {
      dispatch(showAlert(data));
    }
  }, [workShiftId, formData, workerId, shift]);

  const onDelete = React.useCallback(() => {
    if (shift) {
      const data = {
        title: 'Удаление смены',
        description: 'Вы действительно хотите удалить смену?',
        name: ALERT.REMOVE_SHIFT,
        type: ALERT_TYPE.REMOVE,
        data: {
          shiftId: shift.id,
        },
      };

      dispatch(showAlert(data));
    }
  }, [shift]);

  return (
    <div className={`${workingShiftInfo}__actions`}>
      {shift && !isStartedCreatingShift && (
        <>
          {[EShiftType.CANCELED, EShiftType.ADDITIONAL].includes(shift.type) && (
            <Button text="Удалить" onClick={onDelete} background={COLORS.PINK} border small />
          )}
          {[EShiftType.ACCEPTED, EShiftType.DEFAULT].includes(shift.type) && (
            <>
              <Button text="Удалить" onClick={onDelete} background={COLORS.PINK} border small />
              <Button text="Сохранить" background={COLORS.BLUE} onClick={onUpdate} small />
            </>
          )}
        </>
      )}
      {(isStartedCreatingShift || (!shift && !useScheduleTemplate)) && (
        <Button
          text="Создать"
          disabled={!workShiftId && useScheduleTemplate}
          background={COLORS.BLUE}
          onClick={onCreate}
          small
        />
      )}
    </div>
  );
};
