import { createSlice } from '@reduxjs/toolkit';

export interface IColumn {
  columns: {
    title: string;
    name: string;
    check: boolean;
    width?: string;
  }[];
}

const columnsGeo: IColumn = {
  columns: [
    {
      title: 'Дата',
      name: 'date',
      check: true,
      width: '100px',
    },
    {
      title: 'Время начала смены',
      name: 'shift_start_time',
      check: true,
      width: '200px',
    },
    {
      title: 'Цех',
      name: 'department',
      check: true,
      width: '125px',
    },
    {
      title: 'Тип смены',
      name: 'working_shift',
      check: true,
      width: '125px',
    },
    {
      title: 'Исполнитель',
      name: 'user_name',
      check: true,
      width: '250px',
    },
    {
      title: 'Профессия',
      name: 'profession',
      check: true,
      width: '250px',
    },
    {
      title: 'Автор',
      name: 'author',
      check: true,
      width: '250px',
    },
    {
      title: 'Задача',
      name: 'title',
      check: true,
      width: '350px',
    },
    {
      title: 'Описание',
      name: 'description',
      check: true,
      width: '350px',
    },
    {
      title: 'Тип задачи',
      name: 'type',
      check: true,
      width: '250px',
    },
    {
      title: 'Вид задачи',
      name: 'class',
      check: true,
      width: '250px',
    },
    {
      title: 'Тип гео',
      name: 'geo_type',
      check: true,
      width: '130px',
    },
    {
      title: 'Зона(-ы) гео',
      name: 'geo_zones',
      check: true,
      width: '200px',
    },
    {
      title: 'Статус задачи',
      name: 'status',
      check: true,
      width: '130px',
    },
    {
      title: 'Начало',
      name: 'begin_time',
      check: true,
      width: '100px',
    },
    {
      title: 'Конец',
      name: 'end_time',
      check: true,
      width: '100px',
    },
    {
      title: 'Факт. начало',
      name: 'real_start_time',
      check: true,
      width: '120px',
    },
    {
      title: 'Факт. конец',
      name: 'real_end_time',
      check: true,
      width: '120px',
    },
    {
      title: 'Прод.',
      name: 'duration',
      check: true,
      width: '120px',
    },
    {
      title: 'Факт. прод.',
      name: 'real_duration',
      check: true,
      width: '120px',
    },
    {
      title: 'Отклонение по времени',
      name: 'time_deviation',
      check: true,
      width: '125px',
    },
    {
      title: 'Соотношение план/факт',
      name: 'correlation',
      check: true,
      width: '125px',
    },
    {
      title: 'Отклонение по гео',
      name: 'geo_deviation',
      check: true,
      width: '125px',
    },
    {
      title: 'Фотоотчет в начале',
      name: 'need_photo_report',
      check: true,
      width: '125px',
    },
    {
      title: 'Коммент. работника',
      name: 'user_comment',
      check: true,
      width: '125px',
    },
    {
      title: 'Статус НС',
      name: 'supervisor_status',
      check: true,
      width: '150px',
    },
    {
      title: 'Комментарий НС',
      name: 'supervisor_comment',
      check: true,
      width: '350px',
    },
    {
      title: 'Статус НУ',
      name: 'department_status',
      check: true,
      width: '150px',
    },
    {
      title: 'Комментарий НУ',
      name: 'department_comment',
      check: true,
      width: '350px',
    },
  ],
};

const columnsShifts: IColumn = {
  columns: [
    {
      title: 'Дата',
      name: 'date',
      check: true,
      width: '100px',
    },
    {
      title: 'Цех',
      name: 'department',
      check: true,
      width: '125px',
    },
    {
      title: 'Тип смены',
      name: 'working_shift',
      check: true,
      width: '125px',
    },
    {
      title: 'Исполнитель',
      name: 'user_name',
      check: true,
      width: '250px',
    },
    {
      title: 'Профессия',
      name: 'profession',
      check: true,
      width: '250px',
    },
    {
      title: 'План начало',
      name: 'begin_time',
      check: true,
      width: '100px',
    },
    {
      title: 'План окончание',
      name: 'end_time',
      check: true,
      width: '100px',
    },
    {
      title: 'Обед',
      name: 'dinner',
      check: true,
      width: '100px',
    },
    {
      title: 'Чекин',
      name: 'checkin',
      check: true,
      width: '100px',
    },
    {
      title: 'Чекаут',
      name: 'checkout',
      check: true,
      width: '100px',
    },
    {
      title: 'Отклонения от плана',
      name: 'time_deviation',
      check: true,
      width: '125px',
    },
    {
      title: 'Кол-во задач в смене',
      name: 'tasks_amount',
      check: true,
      width: '125px',
    },
    {
      title: 'Прод. задач — план',
      name: 'tasks_duration',
      check: true,
      width: '125px',
    },
    {
      title: 'Прод. задач — факт',
      name: 'tasks_real_duration',
      check: true,
      width: '125px',
    },
    {
      title: 'Время без загрузки — план',
      name: 'not_planned',
      check: true,
      width: '150px',
    },
    {
      title: 'Время без загрузки — факт',
      name: 'real_free_time',
      check: true,
      width: '150px',
    },
    {
      title: 'Загруженность — план',
      name: 'planned',
      check: true,
      width: '150px',
    },
    {
      title: 'Загруженность — факт',
      name: 'real_planned',
      check: true,
      width: '150px',
    },
  ],
};

const columnsHelmet: IColumn = {
  columns: [
    {
      title: 'Дата',
      name: 'date',
      check: true,
      width: '100px',
    },
    {
      title: 'Время',
      name: 'time',
      check: true,
    },
    {
      title: 'ФИО',
      name: 'user_name',
      check: true,
      width: '250px',
    },
    {
      title: 'Локация',
      name: 'zone_name',
      check: true,
    },
    {
      title: 'Цех',
      name: 'department',
      check: true,
      width: '125px',
    },
    {
      title: 'Исправлено',
      name: 'fixed',
      check: true,
      width: '125px',
    },
    {
      title: 'Время исправления',
      name: 'fixed_time',
      check: true,
      width: '170px',
    },
  ],
};

const initialState: IColumn = {
  columns: [
    {
      title: 'Дата',
      name: 'date',
      check: true,
      width: '100px',
    },
    {
      title: 'Цех',
      name: 'department',
      check: true,
      width: '125px',
    },
    {
      title: 'Тип смены',
      name: 'working_shift',
      check: true,
      width: '125px',
    },
    {
      title: 'Исполнитель',
      name: 'user_name',
      check: true,
      width: '250px',
    },
    {
      title: 'Профессия',
      name: 'profession',
      check: true,
      width: '250px',
    },
    {
      title: 'Автор',
      name: 'author',
      check: true,
      width: '250px',
    },
    {
      title: 'Задача',
      name: 'title',
      check: true,
      width: '350px',
    },
    {
      title: 'Описание',
      name: 'description',
      check: true,
      width: '350px',
    },
    {
      title: 'Тип задачи',
      name: 'type',
      check: true,
      width: '250px',
    },
    {
      title: 'Вид задачи',
      name: 'class',
      check: true,
      width: '250px',
    },
    {
      title: 'Тип гео',
      name: 'geo_type',
      check: true,
      width: '130px',
    },
    {
      title: 'Зона(-ы) гео',
      name: 'geo_zones',
      check: true,
      width: '200px',
    },
    {
      title: 'Статус задачи',
      name: 'status',
      check: true,
      width: '130px',
    },
    {
      title: 'Начало',
      name: 'begin_time',
      check: true,
      width: '100px',
    },
    {
      title: 'Конец',
      name: 'end_time',
      check: true,
      width: '100px',
    },
    {
      title: 'Факт. начало',
      name: 'real_start_time',
      check: true,
      width: '120px',
    },
    {
      title: 'Факт. конец',
      name: 'real_end_time',
      check: true,
      width: '120px',
    },
    {
      title: 'Прод.',
      name: 'duration',
      check: true,
      width: '120px',
    },
    {
      title: 'Факт. прод.',
      name: 'real_duration',
      check: true,
      width: '120px',
    },
    {
      title: 'Отклонение по времени',
      name: 'time_deviation',
      check: true,
      width: '125px',
    },
    {
      title: 'Соотношение план/факт',
      name: 'correlation',
      check: true,
      width: '125px',
    },
    {
      title: 'Отклонение по гео',
      name: 'geo_deviation',
      check: true,
      width: '125px',
    },
    {
      title: 'Фотоотчет в начале',
      name: 'need_photo_report',
      check: true,
      width: '125px',
    },
    {
      title: 'Коммент. работника',
      name: 'user_comment',
      check: true,
      width: '125px',
    },
    {
      title: 'Статус НС',
      name: 'supervisor_status',
      check: true,
      width: '150px',
    },
    {
      title: 'Комментарий НС',
      name: 'supervisor_comment',
      check: true,
      width: '350px',
    },
    {
      title: 'Статус НУ',
      name: 'department_status',
      check: true,
      width: '150px',
    },
    {
      title: 'Комментарий НУ',
      name: 'department_comment',
      check: true,
      width: '350px',
    },
  ],
};

const columnsIncidents: IColumn = {
  columns: [
    {
      title: 'Дата и время',
      name: 'event_time',
      check: true,
      width: '150px',
    },
    {
      title: 'Тип происшествия',
      name: 'type',
      check: true,
      width: '180px',
    },
    {
      title: 'Цех',
      name: 'department',
      check: true,
      width: '150px',
    },
    {
      title: 'Профессия',
      name: 'profession',
      check: true,
      width: '180px',
    },
    {
      title: 'ФИО',
      name: 'fio',
      check: true,
      width: '250px',
    },
    {
      title: 'Локация',
      name: 'zone',
      check: true,
      width: '150px',
    },
    {
      title: 'Дополнительно',
      name: 'data',
      check: true,
    },
  ],
};

const columnsPPE: IColumn = {
  columns: [
    {
      title: 'Дата',
      name: 'created_at',
      check: true,
      width: '100px',
    },
    {
      title: 'Цех',
      name: 'department',
      check: true,
      width: '300px',
    },
    {
      title: 'Профессия',
      name: 'profession',
      check: true,
      width: '500px',
    },
    {
      title: 'Локация',
      name: 'zone_name',
      check: true,
      width: '150px',
    },
    {
      title: 'ФИО',
      name: 'fio',
      check: true,
      width: '250px',
    },
    {
      title: 'Время в локации',
      name: 'helmet_info',
      check: true,
      width: '500px',
    },
  ],
};

export const ReportsColumnsSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    updateColumns(state, action) {
      switch (action.payload) {
        case 'tasks':
          state.columns = initialState.columns;
          break;
        case 'tasks-geo':
          state.columns = columnsGeo.columns;
          break;
        case 'shifts':
          state.columns = columnsShifts.columns;
          break;
        case 'helmet':
          state.columns = columnsHelmet.columns;
          break;
        case 'incidents':
          state.columns = columnsIncidents.columns;
          break;
        case 'ppe':
          state.columns = columnsPPE.columns;
          break;
      }
    },
    changeColumn(state, action) {
      const index = state.columns.findIndex(item => item.name === action.payload.name);
      state.columns.splice(index, 1, {
        title: state.columns[index].title,
        check: action.payload.check,
        name: state.columns[index].name,
        width: state.columns[index].width,
      });
    },
    chooseAllColumns(state, action) {
      state.columns = state.columns.map(item => {
        return {
          title: item.title,
          check: action.payload,
          name: item.name,
          width: item.width,
        };
      });
    },
  },
});

export default ReportsColumnsSlice.reducer;
export const { updateColumns, changeColumn, chooseAllColumns } = ReportsColumnsSlice.actions;
