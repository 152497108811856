import * as React from 'react';
import cx from 'classnames';
import { ReactComponent as ActiveCalendarNotification } from '~/assets/active-calendar-notification.svg';
import { CreateSchedulePopup } from '~/components/schedule/popup/CreateSchedulePopup';
import { useSelector } from 'react-redux';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { tableRowClassName, tableColClassName } from '~/helpers/constants';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

interface IFC {
  userId: number;
  edit: boolean;
  rowIndex: number;
  rowLength: number;
}

const ItemUser = ({ userId, edit, rowIndex, rowLength }: IFC) => {
  const user = useSelector(selectUser(userId));
  const companyOptions = useSelector(selectCompanyOptions);
  const [isShownInfo, setIsShownInfo] = React.useState<boolean>(false);

  if (!user) {
    return null;
  }

  const handleShownInfo = () => {
    setIsShownInfo(true);
  };

  const handleCloseInfo = () => {
    setIsShownInfo(false);
  };

  return (
    <div className={tableRowClassName}>
      <div className={cx(tableColClassName, `${tableColClassName}_avatar`)}>
        <button className="user-avatar">
          {user.lastname?.charAt(0)?.toUpperCase()}
          {user.firstname?.charAt(0)?.toUpperCase()}
        </button>
      </div>
      <div
        className={cx(tableColClassName, `${tableColClassName}_name`, {
          'full-width': !companyOptions.use_schedule_template,
        })}
      >
        {user.lastname} {user.firstname} {user.middlename}
      </div>
      {edit && companyOptions.use_schedule_template && (
        <div className={cx(tableColClassName, `${tableColClassName}_create-schedule`)}>
          <ActiveCalendarNotification onClick={handleShownInfo} />
        </div>
      )}
      {edit && isShownInfo && (
        <CreateSchedulePopup
          onClose={handleCloseInfo}
          user={user}
          rowIndex={rowIndex}
          rowLength={rowLength}
        />
      )}
    </div>
  );
};

export default ItemUser;
