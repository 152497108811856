import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';
import { workingShiftsAdapter } from '~/redux/modules/workingShiftsModule';
import { selectWorkShiftId } from './stateSelectors';
import { selectWorkShiftIds } from './stateSelectors';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

export const workingShiftsSelector = workingShiftsAdapter.getSelectors<IStore>(
  state => state.workingShifts,
);

export const selectWorkingShifts = createSelector(
  (state: IStore) => workingShiftsSelector.selectAll(state),
  workingShifts => workingShifts.filter(shift => !shift.deleted),
);

export const selectDataWorkingShifts = createSelector(
  (state: IStore) => workingShiftsSelector.selectAll(state),
  workingShifts =>
    workingShifts
      .map(shift => ({
        id: shift.id,
        name: shift.name,
        deleted: shift.deleted,
        check: false,
      }))
      .sort((x, y) => Number(x.deleted) - Number(y.deleted)),
);

export const selectWorkingShiftsWithTrashed = createSelector(
  (state: IStore) => workingShiftsSelector.selectAll(state),
  workingShifts => workingShifts,
);

export const selectWorkingShift = (workingShiftId: number | undefined) =>
  createSelector(
    (state: IStore) => workingShiftsSelector.selectAll(state),
    workingShifts => workingShifts.find(workingShift => workingShift.id === workingShiftId),
  );

export const selectCurrentWorkingShift = createSelector(
  [selectWorkingShifts, selectWorkShiftId],
  (workingShifts, workShiftId) => {
    return workingShifts.find(workingShift => {
      return workingShift.id === workShiftId;
    });
  },
);

export const selectCurrentWorkingShifts = createSelector(
  [selectWorkingShifts, selectWorkShiftIds, selectCompanyOptions],
  (workingShifts, workShiftIds, companyOptions) => {
    return workingShifts.filter(workingShift => {
      return companyOptions.use_schedule_template ? workShiftIds.includes(workingShift.id) : true;
    });
  },
);
