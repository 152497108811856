import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Api, { ITaskReportFilter, TasksReportData, TasksReport } from 'sb_manufacturing_front_api';
import errorHandler from '~/utils/errorHandler';

export const fetchTasksReport = createAsyncThunk(
  'tasksReport/fetchAll',
  async (input: ITaskReportFilter) => {
    const response: TasksReport = await Api.getTasksReport(input);
    if (response.kind === 'ok') {
      return response.data;
    } else errorHandler(response);
  },
);

const initialState: TasksReportData = {
  data: [],
  totalCount: 0,
};

export const tasksReportSlice = createSlice({
  name: 'tasksReport',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTasksReport.fulfilled.type]: (state, action: PayloadAction<TasksReportData>) => {
      if (action.payload) {
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
      }
    },
  },
});

export default tasksReportSlice.reducer;
