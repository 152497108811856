import * as React from 'react';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import Popup from '~/components/popup/Popup';
import { useDispatch, useSelector } from 'react-redux';
import { changeColumn } from '~/redux/modules/reportsColumnsModule';
import { chooseAllColumns } from '~/redux/modules/reportsColumnsModule';
import { selectReportsColumns } from '~/redux/selectors/reportsColumnsSelector';
import { useAppSelector } from '~/hooks/redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

const ReportsFiltersPopup = () => {
  const dispatch = useDispatch();
  const companyOptions = useAppSelector(selectCompanyOptions);

  let columns = useSelector(selectReportsColumns);
  if (!companyOptions.use_schedule_template) {
    columns = columns.filter(column => column.name !== 'working_shift');
  }

  const [allSelected, setAllSelected] = React.useState(true);

  const handelCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(changeColumn({ name: event.target.dataset.filter, check: event.target.checked }));

  const handleChooseAll = () => dispatch(chooseAllColumns(!allSelected));

  React.useEffect(() => {
    const numberActive = columns.reduce((acc, column) => (!column.check ? ++acc : acc), 0);
    setAllSelected(numberActive <= 0);
  }, [columns]);

  return (
    <Popup aside className="reports-filters-popup">
      <AsidePopupHeader>
        <h2>Показывать столбцы</h2>
      </AsidePopupHeader>
      <AsidePopupBody>
        <div className="rf-content">
          <div className="rf-content__all" onClick={handleChooseAll}>
            {allSelected ? 'Сбросить все' : 'Выбрать все'}
          </div>
          {columns.map((column, key) => {
            return (
              <div className="rf-content__line" key={key}>
                <input
                  type="checkbox"
                  checked={column.check}
                  data-filter={column.name}
                  onChange={handelCheckboxChange}
                />
                <span>{column.title}</span>
              </div>
            );
          })}
        </div>
      </AsidePopupBody>
    </Popup>
  );
};

export default ReportsFiltersPopup;
