import { INewShiftsListPeriod, IVacation } from 'sb_manufacturing_front_api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';
import { getPeriodShifts } from '~/redux/modules/shiftsModule';

export const vacationsAdapter = createEntityAdapter<IVacation>({
  selectId: vacations => vacations.id,
});

export interface IVacationsState {
  ids: EntityId[];
  entities: Dictionary<IVacation>;
}

const vacationsSlice = createSlice({
  name: 'vacations',
  initialState: vacationsAdapter.getInitialState(),
  reducers: {
    addOne: vacationsAdapter.addOne,
    create: vacationsAdapter.upsertMany,
    update: vacationsAdapter.upsertMany,
    remove: vacationsAdapter.removeMany,
    clear: vacationsAdapter.removeAll,
    createShifts(state, action) {
      vacationsAdapter.upsertMany(state, action);
    },
    updateShifts(state, action) {
      vacationsAdapter.upsertMany(state, action);
    },
  },
  extraReducers: builder => {
    builder.addCase(getPeriodShifts.fulfilled, (state, action) => {
      const payload = action.payload as INewShiftsListPeriod;
      if (payload?.vacations) {
        vacationsAdapter.upsertMany(state, payload.vacations);
      }
    });
  },
});

export default vacationsSlice.reducer;

export const { create, update, remove, clear } = vacationsSlice.actions;
