import React from 'react';
import SelectsField from '~/components/form/select/Select';
import getTimeOptions from '~/helpers/getTimeOptions';
import CheckboxesField from '~/components/form/checkbox/Checkbox';
import { prepareShiftData } from '~/components/schedule/popup/WorkShiftInfoActions';
import moment from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';

interface IShiftEditForm {
  state: IStateForm;
  setState: React.Dispatch<React.SetStateAction<IStateForm>>;
  timeZone: number;
  editable: boolean;
}

const editFormValidation = (
  formData: IStateForm,
  setError: React.Dispatch<string | null>,
  timeZone: number,
) => {
  let error: string | null = null;
  const shiftData = prepareShiftData(formData, timeZone, 0, moment('2024-10-10 00:00:00'));
  if (Boolean(shiftData.has_dinner)) {
    if (moment(shiftData.dinner_start_time).isSameOrAfter(moment(shiftData.dinner_end_time))) {
      console.log(shiftData.dinner_start_time, shiftData.dinner_end_time);
      error = 'Начало обеда должно быть раньше окончания обеда';
    }

    if (
      moment(shiftData.dinner_start_time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).isBefore(
        moment(shiftData.shift_start_time, FORMAT_MOMENT.YYYYMMDD_HHMMSS),
      )
    ) {
      console.log(shiftData.dinner_start_time, shiftData.shift_start_time);
      error = 'Начало обеда должно быть позже начала смены';
    }

    if (
      moment(shiftData.dinner_end_time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).isAfter(
        moment(shiftData.shift_end_time, FORMAT_MOMENT.YYYYMMDD_HHMMSS),
      )
    ) {
      console.log(shiftData.dinner_end_time, shiftData.shift_end_time);
      error = 'Окончания обеда должно быть раньше окончания смены';
    }
  }

  setError(error);
};

const ShiftEditForm = ({ state, setState, timeZone, editable }: IShiftEditForm) => {
  const getTime = getTimeOptions();
  const [error, setError] = React.useState<string | null>(null);

  const handelOnInput = (value: string | boolean, name: string) => {
    setState((prevState: IStateForm) => {
      const newState = {
        ...prevState,
        [name]: value,
      };
      editFormValidation(newState, setError, timeZone);
      return newState;
    });
  };

  return (
    <div className="shiftEditForm">
      <div className="row">
        <div className="time-params">
          <div className="time-inputs">
            <h3 className="time-inputs__title">{'Начало смены'}</h3>
            <div className="time-inputs__item">
              <SelectsField
                name={'shift_start_hour'}
                options={getTime.hours}
                onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                defaultValue={state['shift_start_hour'] ?? '9'}
                disabled={!editable}
                hideIndicator
              />
            </div>
            <div className="time-inputs__item">
              <SelectsField
                name={'shift_start_minutes'}
                options={getTime.minutes}
                onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                defaultValue={state['shift_start_minutes'] ?? '0'}
                disabled={!editable}
                hideIndicator
              />
            </div>
          </div>
          <div className="time-inputs">
            <h3 className="time-inputs__title">{'Окончание смены'}</h3>
            <div className="time-inputs__item">
              <SelectsField
                name={'shift_end_hour'}
                options={getTime.hours}
                onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                defaultValue={state['shift_end_hour'] ?? '21'}
                disabled={!editable}
                hideIndicator
              />
            </div>
            <div className="time-inputs__item">
              <SelectsField
                name={'shift_end_minutes'}
                options={getTime.minutes}
                onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                defaultValue={state['shift_end_minutes'] ?? '0'}
                disabled={!editable}
                hideIndicator
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <CheckboxesField
          name={'has_dinner'}
          label="Есть обед"
          defaultValue={state['has_dinner']}
          disabled={!editable}
          onChange={(value, name) => handelOnInput(value, name)}
        />
      </div>
      {state['has_dinner'] == 1 && (
        <div className="row">
          <div className="time-params">
            <div className="time-inputs">
              <h3 className="time-inputs__title">{'Начало обеда'}</h3>
              <div className="time-inputs__item">
                <SelectsField
                  name={'dinner_start_hour'}
                  options={getTime.hours}
                  onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                  defaultValue={state['dinner_start_hour'] ?? '11'}
                  disabled={!editable}
                  hideIndicator
                />
              </div>
              <div className="time-inputs__item">
                <SelectsField
                  name={'dinner_start_minutes'}
                  options={getTime.minutes}
                  onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                  defaultValue={state['dinner_start_minutes'] ?? '0'}
                  disabled={!editable}
                  hideIndicator
                />
              </div>
            </div>
            <div className="time-inputs">
              <h3 className="time-inputs__title">{'Окончание обеда'}</h3>
              <div className="time-inputs__item">
                <SelectsField
                  name={'dinner_end_hour'}
                  options={getTime.hours}
                  onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                  defaultValue={state['dinner_end_hour'] ?? '12'}
                  disabled={!editable}
                  hideIndicator
                />
              </div>
              <div className="time-inputs__item">
                <SelectsField
                  name={'dinner_end_minutes'}
                  options={getTime.minutes}
                  onChange={(value, name) => handelOnInput(value?.value ?? '', name)}
                  defaultValue={state['dinner_end_minutes'] ?? '0'}
                  disabled={!editable}
                  hideIndicator
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {error && <div className="row has-error">{error}</div>}
    </div>
  );
};
export default ShiftEditForm;
